import { graphql } from 'gatsby';
import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Blog from '../../components/blog/layout-two';
import SEO from '../../components/seo';
import Heading from '../../components/shared/heading';
import Text from '../../components/shared/text';
import InstagramArea from '../../containers/global/instagram';
import Footer from '../../containers/layout/footer';
import Header from '../../containers/layout/header';
import Layout from '../../containers/layout/layout';
import CategoryWidget from '../../containers/widgets/categories';
import CTAWidget from '../../containers/widgets/cta';
import InstagramWidget from '../../containers/widgets/instagram';
import RecentPostWidget from '../../containers/widgets/recent-post';
import SearchWidget from '../../containers/widgets/search';
import SubscribeWidget from '../../containers/widgets/subscribe';
import {
  BlogListWrap,
  PageHeader,
  SectionWrap,
  SidebarWrap
} from './tag-template.stc';

const TagTemplate = ({ data, pageContext, location, ...restProps }) => {
  const blogs = data.allMarkdownRemark.edges.filter(blog => {
    return moment().isAfter(blog.node.fields.dateSlug);
  });
  const { totalCount } = data.allMarkdownRemark;
  const { tag } = pageContext;
  const { categoryText, categoryHeading, blogStyle } = restProps;
  let numberOfPosts = `${totalCount} wpisów`;

  if (totalCount === 0) {
    numberOfPosts = 'Brak wpisów';
  } else if (totalCount === 1) {
    numberOfPosts = '1 wpis';
  } else if (totalCount < 5) {
    numberOfPosts = `${totalCount} wpisy`;
  }

  return (
    <Layout>
      <SEO title={`Tag: ${tag}`} />
      <Header />
      <div className="main-content">
        <SectionWrap>
          <Container>
            <Row>
              <Col xl={8}>
                <PageHeader>
                  <Text {...categoryText}>{tag}</Text>
                  <Heading {...categoryHeading}>{numberOfPosts}</Heading>
                </PageHeader>
                <BlogListWrap>
                  {blogs.map(blog => (
                    <Blog
                      {...blogStyle}
                      key={blog.node.fields.slug}
                      content={{
                        ...blog.node.fields,
                        ...blog.node.frontmatter,
                        excerpt: blog.node.excerpt
                      }}
                    />
                  ))}
                </BlogListWrap>
              </Col>
              <Col xl={4}>
                <SidebarWrap>
                  <SearchWidget />
                  <RecentPostWidget />
                  <InstagramWidget />
                  <CategoryWidget />
                  <CTAWidget />
                  <SubscribeWidget />
                </SidebarWrap>
              </Col>
            </Row>
          </Container>
        </SectionWrap>
        <InstagramArea />
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query BlogByTagQuery($tag: String!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            dateSlug
            postID
          }
          frontmatter {
            category
            date(formatString: "LL", locale: "pl")
            format
            tags
            title
            video_link
            quote_text
            quote_author
            link
            images {
              childImageSharp {
                fluid(
                  maxWidth: 510
                  maxHeight: 350
                  quality: 100
                  srcSetBreakpoints: 6
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
            image {
              childImageSharp {
                fluid(
                  maxWidth: 510
                  maxHeight: 350
                  quality: 100
                  srcSetBreakpoints: 6
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          excerpt(pruneLength: 165, format: PLAIN, truncate: true)
        }
      }
    }
  }
`;

TagTemplate.defaultProps = {
  categoryText: {
    textTransform: 'capitalize',
    mb: '11px'
  },
  categoryHeading: {
    as: 'h4',
    color: 'secondaryColor'
  },
  blogStyle: {
    mb: '30px'
  }
};

export default TagTemplate;
